body {
  overflow: hidden;
}

h3 {
  font-size: 50px !important;
  margin-bottom: 20px;
}

section {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(calc(var(--vh) * 100) - 120px);
  padding: 50px 70px;
  position: relative;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  display: flex !important;
  align-items: flex-end;
  z-index: 0;
}

#section01 {
  background:linear-gradient(to bottom right, rgba(23,150,212, 0.8), rgba(39,198,71, 0.8)), url('images/image01.jpeg') ;
}

#section02 {
  background: url('images/image02.jpeg') ;
}

#section02::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, rgba(185, 66, 66, 0.8), rgba(143, 3, 3, 0.8));
  z-index: -1;
}
#section03 {
  background:linear-gradient(to bottom right, rgba(45, 165, 223, 0.8), rgba(9, 22, 108, 0.8)), url('images/image03.jpeg') ;
}

#section04 {
  background: linear-gradient(to bottom right, rgba(19, 145, 248, 0.8),rgba(4, 178, 217, 0.8)), url('images/image04_landscapemode.jpg');
  background-position: top;
}
.section-content-container {
  /* position: absolute; */
  bottom: 20px;
  width: 100%;
}

.section-content-container > * {
  margin-top: 0px;
}

.section-body {
  color: white;
  width: 100%;
  max-width: 57%;
}

.section-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.section-footer a {
  margin: auto;
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.ellipse {
  border-radius: 20px;
  width: 100%;
  height: 2px;
  background-color: white;
  display: none;
}

.learn-more {
  margin-left: 20px;
}

.section-footer a:hover {
  text-decoration: none;
  color: white;
}

.section-footer a:hover + .ellipse{
  display: block;
}

.company-logo{
  width: 200px;
  max-height: 67px;
  filter: brightness(0) invert(1);
}

.number-logo {
  height: 50px;
  display: block;
}
.number-logo-text {
  font-family: Bison !important;
  color: white;
  font-size: 60px;
  font-weight: 800;
}

.left-footer {
  display: flex;
  align-items: center;
}
.right-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}


@keyframes bouncing {
  0% {bottom: 0;}
  50% {bottom: 20px;}
  100% {bottom: 0;}
}

.arrowDiv {
  height: 40px;
  position: relative;
}
.arrow {
  animation: bouncing 1s infinite ease-in-out;
  bottom: 0;
  display: block;
  height: 25px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 65px;
  filter: brightness(0) invert(1);
}

.arrow:hover {
  cursor: pointer;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px){
  h3 {
    font-size: 40px !important;
  }
  section {
    padding: 40px 100px;
  }
}


@media only screen and (max-width: 1200px) {
  
  h3 {
    font-size: 25px !important;
    margin: 0
  }

  #main-logo {
    width: 50px;
    height: 50px;
  }

  .company-logo{
    width: 130px;
    max-height: 50px;
  }

  .section-footer a {
    font-size: 15px;
  }

  section {
    background-size: auto 100% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  } 

  #section01 {
    background-position: 70% !important;
  }
  #section02 {
    background-position: 15% !important;
  }
  .section-content-container > * {
    margin-top: 10px;
  }

  .section-body {
    width: 100%;
    max-width: 80%;
  }

  .number-logo {
    width: 35px;
    display: block;
  }

  .number-logo-text {
    font-size: 40px;
  }

  .navbar-logo {
    width: 150px;
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
  h3 {
    font-size: 40px !important;
  }
  section {
    padding: 40px 70px;
  }
}


@media screen and (max-width: 480px) { /* mobile screen */
  section {
    height: calc(calc(var(--vh) * 100) - 70px);
    padding: 30px 10px;
    /* padding-bottom: 10px; */
  }
  
  h3 {
    font-size: 25px !important;
  }

  .company-logo{
    width: 100px;
    height: 100%;
  }
  
  .section-footer a {
    font-size: 15px;
  }

  .number-logo {
    width: 50px;
    display: block;
  }

  .number-logo-text {
    font-size: 30px;
  }

  .section-footer img {
    margin-right: 10px;
  }

  .section-body {
    width: 100%;
    max-width: 100%;
  }

  .section-content-container > * {
    margin-top: 30px;
  }

}
