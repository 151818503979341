@font-face {
  src: url('./fonts/Product Sans Regular.ttf');
  font-family: ProductSans;
}

@font-face {
  src: url('./fonts/Product Sans Bold.ttf');
  font-weight: bold;
  font-family: ProductSans;
}

@font-face {
  src: url('./fonts/Product Sans Italic.ttf');
  font-style: italic;
  font-family: ProductSans;
}

@font-face {
  src: url('./fonts/Product Sans Bold Italic.ttf');
  font-weight: bold;
  font-style: italic;
  font-family: ProductSans;
}

@font-face {
  src: url('./fonts/Bison-Bold.ttf');
  font-weight: bold;;
  font-family: Bison;
}

:root {
  --logo-color: #21C253;
}

body {
  margin: 0;
  font-family: ProductSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: ProductSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  box-sizing: border-box;
  outline: none;
}
