:root {
  --black: #11162a;
  --gray: #555;
}
.container {
  padding: 30px 185px;
  line-height: 1.75em;
  font-size: 16px;
  color: var(--gray);
  height: fit-content;
}

.container > * {
  margin-bottom: 40px;
}

.green-text {
  color: var(--logo-color);
}

.quote {
  margin-top: 20px;
  font-weight: 800;
  /* line-height: 1.75em; */
}

.center {
  text-align: center;
}

.about-header {
  padding: 75px 0;
}

.header-text {
  color: var(--black);
  font-size: 40px;
  font-weight: 800;
}
.sub-title {
  color: var(--black);
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: 800;
}

.sub-title-header {
  font-size: 18px;
}
.text {
  text-align: justify;
}

.contact-detail {
  font-size: 20px;
}

.key {
  color: var(--black);
}

.value {
  text-indent: 20px;
}

.email {
  text-decoration: none;
  color: var(--black);
}

.contact-section {
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--black);
}

.contact-header {
  font-weight: 800;
  width: 15%;
}

@media screen and (max-width: 1050px) {
  .contact-detail {
    font-size: 15px;
  }
  .contact-header {
    width: 30%;
  }
}
@media screen and (max-width: 480px) {
  .container {
    padding: 15px 10px;
  }

  .about-header {
    font-size: 30px;
    padding: 70px 0;
  }

  .sub-title-header {
    font-size: 12px;
  }

  .sub-title {
    font-size: 20px;
  }

  .contact-header {
    width: 40%;
  }

  .contact-detail {
    font-size: 15px;
  }
}