.header-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 70px;
}

.logo-wrapper {
  display: flex;
}

.logo-wrapper img {
  height: 40px;
}

.button-wrapper {
  font-size: 20px;
  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-wrapper button {
  border: none;
  color: white;
  background-color: var(--logo-color);
  padding: 10px 7px;
  border-radius: 4px;
  cursor: pointer;
  width: 150px;
  height: 60px;
  font-size: 20px;
}

.about-button a {
  color: #555;
  text-decoration: none;
}

@media screen and (max-width: 1260px) {
  .button-wrapper {
    width: 35%;
  }
}

@media screen and (max-width: 800px) {
  .button-wrapper {
    width: 40%;
  }
}

@media screen and (max-width: 480px) {
  .header-navbar {
    padding: 15px 10px;
  }

  .logo-wrapper img {
    height: 25px;
    width: 100px;
  }

  .button-wrapper {
    font-size: 15px;
    width: 50%;
  }
  .button-wrapper button {
    width: 100px;
    height: 40px;
    font-size: 15px;
    padding: 5px;
  }
}